
export default function user(state = null, action) {
    switch (action.type) {
        case 'domain/user/addData': {
            return action.payload
        }

        default:
            return state
    }
}