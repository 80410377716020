export const options = (type, yPrameter, range) => ({
  chart: {
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: false,
    },
    toolbar: {
      show: false,
      autoSelected: 'zoom',
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },

  fill: {
    // type: 'gradient',
    // gradient: {
    //     shadeIntensity: 0,
    //     inverseColors: true,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 90, 100]
    // },
  },
  stroke: {
    show: true,
    // curve: 'smooth',
    lineCap: 'butt',
    colors: ['#24beff', '#34c100', '#ffbf12'],
    width: 3,
    dashArray: 0,
  },
  yaxis: {
    title: {
      text: yPrameter,
    },
    labels: {
      formatter: function (val) {
        return val;
      },
      style: {
        colors: '#909090',
        fontSize: '2vw',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 100,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
  },
  xaxis: {
    type,
    labels: {
      format: labelFromRange(range),
      style: {
        colors: '#909090',
        fontSize: '2vw',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 200,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
  },
  legend: {
    fontSize: '8vw',
    fontFamily: 'Helvetica, Arial',
    fontWeight: 400,
    labels: {
      colors: '#909090',
    },
    itemMargin: {
      horizontal: 6,
      vertical: 16,
    },
  },
  tooltip: {
    followCursor: true,
    shared: false,
    x: {
      show: true,
    },
    y: {
      show: false,
      // formatter: function (val) {
      //     return val
      // }
    },
    style: {
      colors: '#121212',
      fontSize: '2vw',
      fontFamily: undefined,
    },
  },
});

export const startDate = {
  '1D': new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleString('en'),
  '1M': new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleString('en'),
  '6M': new Date(new Date().setDate(new Date().getDate() - 180)).toLocaleString('en'),
  '2Y': new Date(new Date().setDate(new Date().getDate() - 30 * 24)).toLocaleString('en'),
  Max: null,
};

function labelFromRange(range) {
  switch (range) {
    case '1D':
      return 'HH';
    case '1M':
      return 'dd';
    case '6M':
      return 'MMM';
    case 'Max':
      return 'MMM';

    default:
      return 'HH';
  }
}

export const fillData = {
  '1D': (dataArr) => (dataArr.length ? fillD(dataArr, 1 * 24) : []),
  '1M': (dataArr) => (dataArr.length ? fillD(dataArr, 30 * 24) : []),
  '6M': (dataArr) => (dataArr.length ? fillD(dataArr, 6 * 30 * 24) : []),
  Max: (dataArr) => (dataArr.length ? fillD(dataArr) : []),
};

function fillD(arr, hoursBefore) {
  const newArr = [];
  const date = new Date();

  newArr.push(...arr);

  newArr.push({ ...arr[arr.length - 1], date: date.toISOString() });

  if (hoursBefore) {
    date.setHours(date.getHours() - hoursBefore);

    const firstObj = { value: null, date: date.toISOString() };

    newArr.unshift(firstObj);
  }

  return newArr;
}

export const sortGraph = (a, b) => (new Date(a.date) <= new Date(b.date) ? -1 : 1);
