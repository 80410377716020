
export default function signUpUsername(state = "", action) {
    switch (action.type) {
        case 'app/sign-up/username/add': {
            return action.payload
        }
        case 'app/sign-up/username/delete': {
            return action.payload
        }

        default:
            return state
    }
}