
export default function buyP2PSaleData(state = null, action) {
    switch (action.type) {
        case 'app/buyP2PSaleData/add': {
            return action.payload
        }
        case 'app/buyP2PSaleData/delete': {
            return action.payload
        }

        default:
            return state
    }
}