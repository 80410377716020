
export default function agentsListNewPage(state = null, action) {
    switch (action.type) {
        case 'app/my-agent/find-agency/agentsListNewPage/add': {
            return action.payload
        }
        case 'app/my-agent/find-agency/agentsListNewPage/delete': {
            return action.payload
        }
        default:
            return state
    }
}