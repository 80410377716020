
export default function tokensAgentViewMode(state = { accessToken: null, refreshToken: null }, action) {
    switch (action.type) {
        case 'domain/tokensAgentViewMode/addTokens': {
            return { refreshToken: action.payload.refreshToken, accessToken: action.payload.accessToken }
        }
        case 'domain/tokensAgentViewMode/addAccessToken': {
            return { ...state, accessToken: action.payload }
        }
        case 'domain/tokensAgentViewMode/addRefreshToken': {
            return { ...state, refreshToken: action.payload }
        }
        default:
            return state
    }
}