
export default function investedShares(state = null, action) {
    switch (action.type) {
        case 'domain/investedShares/addData': {
            return action.payload
        }
        case 'domain/investedShares/delete': {
            return null;
        }


        default:
            return state
    }
}