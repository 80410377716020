
export default function selectedCurrency(state = null, action) {
    switch (action.type) {
        case 'app/selectedCurrency/add': {
            return action.payload
        }
        case 'app/selectedCurrency/delete': {
            return action.payload
        }

        default:
            return state
    }
}