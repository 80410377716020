
export default function userSales(state = null, action) {
    switch (action.type) {
        case 'domain/userSales/add': {
            return action.payload
        }
        case 'domain/userSales/delete': {
            return action.payload
        }
        default:
            return state
    }
}