
export default function salesFeedWaitingForData(state = true, action) {
    switch (action.type) {
        case 'app/salesFeedWaitingForData/toggle': {
            return action.payload
        }

        default:
            return state
    }
}