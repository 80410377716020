// import store from "../store/store";
export default function scrollContainersToTop() {

    const root = document.querySelector("#root");
    const body = document.querySelector("body");
    const appContainer = document.querySelector(".app-container");

    window.scrollTo(0, 0);
    root.scrollTo(0, 0);
    body.scrollTo(0, 0);
    appContainer.scrollTo(0, 0);

}