export default function fixFractionDigits(num) {
  if (!(num instanceof Object) && num) {
    let temp = num;
    if (temp % 1 !== 0) {
      if (temp % 0.1 === 0) {
        temp = temp.toFixed(1);
      } else {
        temp = temp.toFixed(2);
      }
    }
    return temp;
  } else {
    return 0;
  }
}
