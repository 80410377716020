import "./AuthBtns.css";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const selectProfile = (state) => state.profile;
export default function AuthBtns() {
  const isLoggedIn = useSelector(selectProfile);

  return (
    // !isLoggedIn && (
      <div className="AuthBtns">
        <Link to="/auth/sign-in">
          <p className="AuthBtns__SignUpBtn unnamed-character-style-1 ">SIGN IN</p>
        </Link>
        {/* <p className="AuthBtns__LoginTextAndBtn headline">
          Already have an account?{" "}
          <Link to="/auth/log-in">
            <span>Log in</span>
          </Link>
        </p> */}
      </div>
    // )
  );
}
