
export default function signUpToken(state = null, action) {
    switch (action.type) {
        case 'domain/signUpToken/addToken': {
            return action.payload
        }

        default:
            return state
    }
}