
export default function activityLog(state = null, action) {
    switch (action.type) {
        case 'app/my-agent/activityLog/add': {
            return action.payload
        }
        case 'app/my-agent/activityLog/delete': {
            return action.payload
        }

        default:
            return state
    }
}