const SERVICE = {
  "SIGN UP / IN": 0,
  EXCHANGE: 1,
  WALLET: 2,
  OTHER: 3,
};
const STATUS = {
  OPEN: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  REJECTED: 3,
};
module.exports = {
  SERVICE,
  STATUS
};
