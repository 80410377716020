export default function getRatingColour(SMISocialM){
    let totalFollows = 0;
    if (SMISocialM) {
        for (let j = 0; j < SMISocialM.length; j++) {
            if (SMISocialM[j].social_network === 'Youtube') {
                totalFollows += SMISocialM[j].subscribers;
            }
            else {
                totalFollows += SMISocialM[j].followers;
            }
        }
    }
    let ratingRange = [0, 40000, 100000, 250000, 500000, 1000000, 2500000, 5000000, 7500000, 10000000];
    let ratingNum;
    for (let k = 0; k < ratingRange.length; k++) {
        if (k < ratingRange.length - 1) {
            if ((totalFollows > ratingRange[k]) && (totalFollows < ratingRange[k + 1])) {
                ratingNum = k;
            }
        }
        else {
            if (totalFollows > 10000000) {
                ratingNum = 9;
            }
        }
    }
    let ringColourTypeArray = ["blueRC","blueRC","greenRC","greenRC","purpleRC","purpleRC","purpleRC","yellowRC","yellowRC","whiteRC"];
    let ratingNumColourTypeArray = ["blueRN","blueRN","greenRN","greenRN","purpleRN","purpleRN","purpleRN","yellowRN","yellowRN","whiteRN"];

    return {
        ringColour: ringColourTypeArray[ratingNum],
        ratingNumColour: ratingNumColourTypeArray[ratingNum],
        ratingNumber: ratingNum
    };
}