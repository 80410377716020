
export default function buyIPOSaleData(state = null, action) {
    switch (action.type) {
        case 'app/buyIPOSaleData/add': {
            return action.payload
        }
        case 'app/buyIPOSaleData/delete': {
            return action.payload
        }

        default:
            return state
    }
}