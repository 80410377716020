export default function joinOffers(state = null, action) {
  switch (action.type) {
    case "app/joinOffers/add": {
      return action.payload;
    }
    case "app/joinOffers/delete": {
      return action.payload;
    }

    default:
      return state;
  }
}
