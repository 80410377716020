
export default function moneyCalcUserSMLinks(state = null, action) {
    switch (action.type) {
        case 'app/moneyCalcUserSMLinks/add': {
            return action.payload
        }
        case 'app/moneyCalcUserSMLinks/delete': {
            return null;
        }
        
        default:
            return state
    }
}