
export default function joinRequests(state = null, action) {
    switch (action.type) {
        case 'app/joinRequests/add': {
            return action.payload
        }
        case 'app/joinRequests/delete': {
            return action.payload
        }

        default:
            return state
    }
}