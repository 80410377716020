
export default function sale(state = null, action) {
    switch (action.type) {
        case 'domain/sale/add': {
            return action.payload
        }
        case 'domain/sale/delete': {
            return action.payload
        }

        default:
            return state
    }
}