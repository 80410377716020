
export default function profile(state = null, action) {
    switch (action.type) {
        case 'domain/profile/addData': {
            return action.payload
        }
        case 'domain/profile/deleteProfile': {
            return null;
        }
        // case 'profile/AgencyBackOwnProfile': {
        //     return action.payload;
        // }
        // case 'profile/EditProfile': {

        //     return {
        //         token: store.getState().profile.token,
        //         data: {
        //             ...store.getState().profile,
        //             name: action.payload.name,
        //             email: action.payload.email,
        //             username: action.payload.username,
        //             phone_number: action.payload.phone_number,
        //             city: action.payload.city
        //         }
        //     }
        // }


        default:
            return state
    }
}