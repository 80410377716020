import React from "react";
import { Link } from "react-router-dom";
import "../style.css";
import { navOptions } from "./notificationsConfig";

export default function NotificationNav({ ActionForm, setForm }) {
  const showNavOpt = (navOption) => {
    return (
      <>
        <Link to={navOption === "Education" ? "/education" : "/notification"}>
          <p
            id={`${navOption}Txt`}
            className="unnamed-character-style-1"
            onClick={() => setForm(navOption)}
          >
            {navOption.toUpperCase()}
          </p>
        </Link>
        {ActionForm === navOption && (
          <svg height="2.25vw" width="40%" id={`${navOption}SubLine`}>
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="white"
              stroke-width="100%"
              stroke-linecap="butt"
            />
          </svg>
        )}
      </>
    );
  };

  return (
    <div>
      {showNavOpt(navOptions.updates)}
      {showNavOpt(navOptions.education)}
    </div>
  );
}
