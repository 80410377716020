
export default function agentMetrics(state = null, action) {
    switch (action.type) {
        case 'app/my-agent/agentMetrics/add': {
            return action.payload
        }
        case 'app/my-agent/agentMetrics/delete': {
            return action.payload
        }

        default:
            return state
    }
}