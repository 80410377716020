import './notificationComp.css';
import React, { useEffect, useState } from 'react';
import { markAsRead, notificationsConfig, notificationsUrl } from './notificationsConfig';
import axios from 'axios';
import store from '../../../../store/store';
import { useNavigate } from 'react-router';

import Loader from '../../../../components/common/Loader';

export default function NotificationComp() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // get users notifications
    const getNoti = async () => {
      const token = store?.getState().tokens.accessToken;
      const axiosConfig = { headers: { authorization: token } };

      const res = await axios.get(notificationsUrl, axiosConfig);

      setNotifications(res.data ? res.data : []);
      setLoading(false);

      markAsRead(res.data, axiosConfig);
    };

    getNoti();
  }, []);

  return loading ? (
   <div id='LoaderPosNoti'>
     <Loader/>
   </div> 
  ) : (
    <div>
      {notifications.length === 0 ? (
        <div className='notifi_card'>
          <div className='notifi_text'>
            <div className='notifi_title'>no notification left</div>
          </div>
        </div>
      ) : (
        notifications.map((not, i) => {
          return (
            <div key={i} className={`notifi_card ${not.isActive ? 'notifi_active' : ''}`}>
              <div className='notifi_image'>
                {notificationsConfig(not.type)?.src && (
                  <img src={notificationsConfig(not.type).src} alt='notification_image' />
                )}
              </div>
              <div className='notifi_text'>
                <div className='notifi_title'>{not.text}</div>
                <div className='notifi_body'>
                  {new Date(not.createdAt).toLocaleString().split(',').reverse().join(' ')}
                </div>
              </div>
              <div className='notifi_action'>
                {notificationsConfig(not.type) && notificationsConfig(not.type)?.btn?.txt && (
                  <button
                    onClick={() =>
                      navigate(notificationsConfig(not.type)?.btn?.href(not.actionParams))
                    }
                  >
                    {notificationsConfig(not.type).btn.txt}
                  </button>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
