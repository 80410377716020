
export default function influencer3rdProfile(state = null, action) {
    switch (action.type) {
        case 'domain/influencer3rdProfile/add': {
            return action.payload
        }
        case 'domain/influencer3rdProfile/delete': {
            return null;
        }
        
        default:
            return state
    }
}