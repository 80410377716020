
export default function transactionHistory(state = null, action) {
    switch (action.type) {
        case 'domain/transactionHistory/addData': {
            return action.payload
        }
        case 'domain/transactionHistory/delete': {
            return null;
        }


        default:
            return state
    }
}