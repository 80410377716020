
export default function createTradeP2P(state = null, action) {
    switch (action.type) {
        case 'app/createTradeP2P/add': {
            return action.payload
        }
        case 'app/createTradeP2P/delete': {
            return action.payload
        }

        default:
            return state
    }
}