import { combineReducers } from "redux";

import signUpToken from "./reducers/domain/signUpToken";
import tokens from "./reducers/domain/tokens";
import tokensAgentViewMode from "./reducers/domain/tokensAgentViewMode";
import user from "./reducers/domain/user";
import profile from "./reducers/domain/profile";
import pptPendingList from "./reducers/domain/pptPendingList";
import pptDeliveryList from "./reducers/domain/pptDeliveryList";
import influencer3rdProfile from "./reducers/domain/influencer3rdProfile";
import wallet from "./reducers/domain/wallet";
import transactionHistory from "./reducers/domain/transactionHistory";
import investedShares from "./reducers/domain/investedShares";
import ipoSharesInformation from "./reducers/domain/ipoSharesInformation";
import sale from "./reducers/domain/sale";
import userSales from "./reducers/domain/userSales";
//
import signUpUsername from "./reducers/app/sign-up/username";
import selectedCurrency from "./reducers/app/selectedCurrency";
import forgotPasswordEmail from "./reducers/app/forgotPasswordEmail";
import moneyCalcUserSMFollows from "./reducers/app/moneyCalcUserSMFollows";
import moneyCalcUserSMLinks from "./reducers/app/moneyCalcUserSMLinks";
import pptConvertionTradeData from "./reducers/app/pptConvertionTradeData";
import pptConvertionToProductData from "./reducers/app/pptConvertionToProductData";
import salesFeedWaitingForData from "./reducers/app/salesFeedWaitingForData";
import salesFeedNewPageData from "./reducers/app/salesFeedNewPageData";
import salesFeedPageIndex from "./reducers/app/salesFeedPageIndex";
import salesFeedSelectedCategory from "./reducers/app/salesFeedSelectedCategory";
import salesFeedPriceCategoryDirection from "./reducers/app/salesFeedPriceCategoryDirection";
import exchangeSearchSMIName from './reducers/app/exchangeSearchSMIName';
import exchangeSearchResult from "./reducers/app/exchangeSearchResult";
import exchangeScrollEvent from "./reducers/app/exchangeScrollEvent";
import walletWithdrawValue from "./reducers/app/walletWithdrawValue";
import createTradeP2P from "./reducers/app/createTradeP2P";
import saleId from "./reducers/app/saleId";
import saleRepliedComment from "./reducers/app/saleRepliedComment";
import buyP2PSaleData from "./reducers/app/buyP2PSaleData";
import buyIPOSaleData from "./reducers/app/buyIPOSaleData";
import latestVersionSaved from "./reducers/app/latestVersionSaved";
import joinRequests from "./reducers/app/joinRequests";
import joinOffers from "./reducers/app/joinOffers";
import myAgentLastActiveOn from "./reducers/app/my-agent/agency-details/lastActiveOn";
import myAgentData from "./reducers/app/my-agent/agency-details/agentData";
import myAgentMetrics from "./reducers/app/my-agent/agency-details/agentMetrics";
import myAgentActivityLog from "./reducers/app/my-agent/agency-details/activityLog";
import agentsListNewPage from "./reducers/app/my-agent/find-agency/agentsListNewPage";
import agenciesFeedPageIndex from "./reducers/app/my-agent/find-agency/agenciesFeedPageIndex";
import agenciesFeedWaitingForData from "./reducers/app/my-agent/find-agency/agenciesFeedWaitingForData";
import bankBenificiaryList from './reducers/app/withdraw/bankBenificiaryList';
import processName from "./reducers/app/utiles/processName";
import processIndex from "./reducers/app/utiles/processIndex";
import typeFormIds from "./reducers/app/issuer-registration/typeFormIds";
//
import windowInnerSize from "./reducers/ui/windowInnerSize";
import openingViewDM from "./reducers/ui/openingViewDM";
import navBarsDM from "./reducers/ui/navBarsDM";
import cookiesMessageDM from "./reducers/ui/cookiesMessageDM";
import toRefresh from "./reducers/ui/toRefresh";
import navBarsSelectedSection from "./reducers/ui/navBarsSelectedSection";
import editAvatarImgUrl from "./reducers/ui/editAvatarImgUrl";
import walletArrowsSlideStatus from "./reducers/ui/walletArrowsSlideStatus";
import salesFeedPageLoaderDM from "./reducers/ui/salesFeedPageLoaderDM";
import exchangeSearchDM from "./reducers/ui/exchangeSearchDM";
import exchangeSearchListElements from "./reducers/ui/exchangeSearchListElements";
import microSiteParagraphAnimate from "./reducers/ui/microSite/microSiteParagraphAnimate";
import walletSliderHeight from "./reducers/ui/walletSliderHeight";
import walletSliderIndex from "./reducers/ui/walletSliderIndex";

const rootReducer = combineReducers({
  // Domain data: data that the application needs to show, use, or modify.
  //======================================================================
  signUpToken: signUpToken,
  tokens: tokens,
  tokensAgentViewMode: tokensAgentViewMode,
  user: user,
  profile: profile,
  pptPendingList: pptPendingList,
  pptDeliveryList: pptDeliveryList,
  influencer3rdProfile: influencer3rdProfile,
  wallet: wallet,
  transactionHistory: transactionHistory,
  investedShares: investedShares,
  ipoSharesInformation: ipoSharesInformation,
  sale: sale,
  userSales: userSales,
  // App state: data that is specific to the application's behavior.
  //=================================================================
  latestVersionSaved: latestVersionSaved,
  processName: processName,
  processIndex: processIndex,
  signUpUsername: signUpUsername,
  selectedCurrency: selectedCurrency,
  forgotPasswordEmail: forgotPasswordEmail,
  moneyCalcUserSMLinks: moneyCalcUserSMLinks,
  moneyCalcUserSMFollows: moneyCalcUserSMFollows,
  pptConvertionTradeData: pptConvertionTradeData,
  pptConvertionToProductData: pptConvertionToProductData,
  salesFeedWaitingForData: salesFeedWaitingForData,
  salesFeedNewPageData: salesFeedNewPageData,
  salesFeedPageIndex: salesFeedPageIndex,
  salesFeedSelectedCategory: salesFeedSelectedCategory,
  salesFeedPriceCategoryDirection: salesFeedPriceCategoryDirection,
  exchangeSearchResult: exchangeSearchResult,
  exchangeScrollEvent: exchangeScrollEvent,
  exchangeSearchSMIName: exchangeSearchSMIName,
  walletWithdrawValue: walletWithdrawValue,
  createTradeP2P: createTradeP2P,
  saleId: saleId,
  saleRepliedComment: saleRepliedComment,
  buyP2PSaleData: buyP2PSaleData,
  buyIPOSaleData: buyIPOSaleData,
  joinRequests: joinRequests,
  joinOffers: joinOffers,
  myAgentLastActiveOn: myAgentLastActiveOn,
  myAgentData: myAgentData,
  myAgentMetrics: myAgentMetrics,
  myAgentActivityLog: myAgentActivityLog,
  agentsListNewPage: agentsListNewPage,
  agenciesFeedPageIndex: agenciesFeedPageIndex,
  agenciesFeedWaitingForData: agenciesFeedWaitingForData,
  bankBenificiaryList: bankBenificiaryList,
  typeFormIds:typeFormIds,
  // UI state: data that represents how the UI is currently displayed.
  //==================================================================
  // please use terms: 'DM' - display mode,
  windowInnerSize: windowInnerSize,
  openingViewDM: openingViewDM,
  navBarsDM: navBarsDM,
  cookiesMessageDM: cookiesMessageDM,
  navBarsSelectedSection: navBarsSelectedSection,
  toRefresh: toRefresh, // using to toggle refresh page (by render the page comp. - without reload the page)
  editAvatarImgUrl: editAvatarImgUrl,
  walletArrowsSlideStatus: walletArrowsSlideStatus,
  salesFeedPageLoaderDM: salesFeedPageLoaderDM,
  exchangeSearchDM: exchangeSearchDM,
  exchangeSearchListElements: exchangeSearchListElements,
  microSiteParagraphAnimate: microSiteParagraphAnimate,
  walletSliderHeight: walletSliderHeight,
  walletSliderIndex: walletSliderIndex
});

const exportReducer = (state, action) =>
  rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action);

export default exportReducer;
// export default rootReducer
