import store from "../store/store";
export default function clearStoreWithoutConst() {

    store.dispatch({ type: 'USER_LOGOUT', payload: null })
    store.dispatch({ type: 'ui/openingViewDM/toggle', payload: false });
    store.dispatch({
        type: "ui/windowInnerSize/add",
        payload: { height: window.innerHeight, width: window.innerWidth },
    });
    store.dispatch({ type: 'ui/cookiesMessageDM/toggle', payload: false });

}