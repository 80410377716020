
export default function ipoSharesInformation(state = null, action) {
    switch (action.type) {
        case 'domain/ipoSharesInformation/addData': {
            return action.payload
        }
        case 'domain/ipoSharesInformation/delete': {
            return null;
        }


        default:
            return state
    }
}