
export default function salesFeedNewPageData(state = [], action) {
    switch (action.type) {
        case 'app/salesFeedNewPageData/add': {
            return action.payload
        }
        case 'app/salesFeedNewPageData/delete': {
            return action.payload
        }
        default:
            return state
    }
}