
export default function processName(state = 0, action) {
    switch (action.type) {
        case 'app/utiles/processName/add': {
            return action.payload
        }
        case 'app/utiles/processName/delete': {
            return 0
        }

        default:
            return state
    }
}