
export default function navBarsDM(state = {top: false, bottom: true}, action) {
    switch (action.type) {
        case 'ui/navBarsDM/toggle': {
            return {
                    top: action.payload.top,
                    bottom: action.payload.bottom
                }
            }
        
        default:
            return state
    }
  }