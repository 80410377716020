
export default function wallet(state = null, action) {
    switch (action.type) {
        case 'domain/wallet/addData': {
            return action.payload
        }
        case 'domain/wallet/delete': {
            return null;
        }


        default:
            return state
    }
}