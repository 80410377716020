import "./MembersSlider.css";

import ImageGallery from "react-image-gallery";

// import store from '../../../../../store/store';

import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { Chart } from "react-google-charts";

import StockCreativeCard from "../../../../../components/common/creative-cards/Influencer";
import GraphUserData from "../../../../../components/common/graphs/GraphUserData";

import loadMemberAccount from "./loadMemberAccount.js";

const selectTokens = (state) => state.tokens;

export default function MembersSlider(props) {
  const tokens = useSelector(selectTokens);

  const navigate = useNavigate();

  let tempArray = [];

  const [UpToDate, setUpToDate] = useState(null);
  if (
    props.agencyMembersList &&
    props.AgencyMembersWallet === null &&
    UpToDate === null &&
    props.isAgentAccount
  ) {
    // getMembersStatsData();
    setUpToDate(true);
  }

  // console.log(props.agencyMembersList);
  for (
    let i = 0;
    props.agencyMembersList ? i < props.agencyMembersList.length : i < 0;
    i++
  ) {
    tempArray.push({
      renderItem: () => {
        let cardData = {
          profilePic: props.agencyMembersList[i].influencer.user.profilePic,
          name: props.agencyMembersList[i].influencer.user.name,
          username: props.agencyMembersList[i].influencer.user.username,
          ranking: props.agencyMembersList[i].influencer.ranking,
          ringColour: props.agencyMembersList[i].influencer.ringColour,
          socialMedia: {
            tiktok: null,
            instagram: { follows: 120 },
            youtube: null,
          } /* profile.influencer.socialMedia */,
          niches: props.agencyMembersList[i].influencer.niches,
          city: props.agencyMembersList[i].influencer.user.city,
          currentPrice: props.agencyMembersList[i].influencer.stock.marketPrice,
          currency: props.agencyMembersList[i].influencer.stock.currency.symbol,
          lastChange: props.agencyMembersList[i].influencer.stock.lastChange,
        };
        return (
          <div id={"Member" + i} className="MemberInfoAllSlide">
            <div id="MemberGraphDiv">
              <GraphUserData
                Balance={
                  props.AgencyMembersWallet
                    ? props.AgencyMembersWallet[i].balance
                    : 0
                }
                SMI3rdP={props.SMI3rdP ? true : false}
              />
            </div>
            <div id="MemberInfoCommon" className="headline">
              <StockCreativeCard cardData={cardData} />
              {props.isAgentAccount ? (
                // <Link to={!props.agencyMembersList ? "/" : "/Profile/Agency/Manage/SMI_profile/" + props.agencyMembersList[i].id}>
                <p
                  id="ManageSMIBtn"
                  onClick={async () => {
                    await loadMemberAccount(
                      tokens,
                      props.agencyMembersList[i].influencer.id
                    );
                    navigate("/wallet");
                  }}
                >
                  Manage
                </p>
              ) : // </Link>
              null}
              <div
                className="AgentProfile__FlexContainer"
                style={{ marginTop: "10vw", width: "100%" }}
              >
                <div className="AgentProfile__SquareData">
                  <p>{"--"}</p>
                  <p>Days</p>
                </div>
                <div className="AgentProfile__SquareData">
                  <p>{props.agencyMembersList[i].influencer.stock.currency.symbol}{"--"}</p>
                  <p>Made this week</p>
                </div>
                <div className="AgentProfile__SquareData">
                  <p>{"--"}%</p>
                  <p>Growth Rate</p>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  }
  let slides = tempArray;

  return (
    <div>
      <ImageGallery
        items={slides}
        showNav={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        slideDuration={800}
        slideInterval={3000}
        autoPlay={false}
        infinite={false}
      />
    </div>
  );

  //     async function getMembersStatsData() {
  //         let membersStats = [];

  //         for (let i = 0; i < props.agencyMembersList.length; i++) {
  //             await axios({
  //                 method: 'GET',
  //                 url: `https://web.passionstocks.com/api/user/wallet?api_token=` + props.agencyMembersList[i].api_token,

  //             }).then(async function (response) {
  //                 // console.log('got wallet of member' + i)
  //                 // console.log(response.data);
  //                 membersStats.push(response.data)

  //             }).catch(function (error) {
  //                 // console.log('error to get portfolio data');
  //                 // console.log(error);
  //                 membersStats.push({ balance: null });
  //             });
  //         }
  //         await store.dispatch({ type: 'agency_members_wallet/addWalletData', payload: membersStats })

  //     }
}

function toDigits(number) {
  let output = "",
    sNumber = number.toString();

  for (var i = sNumber.length, count = 0; i >= 0; i--, count++) {
    output =
      count % 3 === 0
        ? " " + sNumber.charAt(i) + output
        : sNumber.charAt(i) + output;
  }
  return output;
}
