
export default function editAvatarImgUrl(state = true, action) {
    switch (action.type) {
        case 'ui/editAvatarImgUrl/add':{
            return action.payload
        }
        case 'ui/editAvatarImgUrl/delete':{
            return null
        }
        default:
            return state
    }
  }