
export default function typeFormIds(state = { formId: null, responseId: null }, action) {
    switch (action.type) {
        case 'app/typeFormIds/add': {
            return action.payload
        }

        default:
            return state
    }
}