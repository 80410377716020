import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import store from "../../store/store";

import "./style.css";
import EmojiWinking from "../../assets/images/common/emoji-winking.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Demo(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  return (
    <div
      className="Demo Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <div className="Page--flex" style={{ minHeight: windowInnerSize.height }}>
        <div>
          <img src={EmojiWinking} alt="" className="Demo__EmojiWinking" />
          <p className="Demo__SignUpTxt">
            Sign up, <br />
            {"&"} I’ll spill the beans.
          </p>
        </div>
        <div>
          <Link to="/auth/sign-up">
            <p className="Demo__SignUpBtn">Sign Up</p>
          </Link>
          <Link to="/auth/log-in">
            <p className="Demo__SignInBtn">Sign In</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
