
export default function exchangeSearchResult(state = null, action) {
    switch (action.type) {
        case 'app/exchangeSearchResult/add':{
            return action.payload
        }
        case 'app/exchangeSearchResult/delete':{
            return action.payload
        }
        default:
            return state
    }
  }