export default function validatePasswordRules(inputtxt)
{
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (decimal.test(String(inputtxt))) {
        // console.log('Correct, try another...')
        return true;
    }
    else {
        // console.log('Wrong...!')
        return false;
    }
} 