
export default function exchangeSearchSMIName(state = null, action) {
    switch (action.type) {
        case 'app/exchangeSearchSMIName/add':{
            return action.payload
        }
        case 'app/exchangeSearchSMIName/delete':{
            return action.payload
        }
        default:
            return state
    }
  }