import './style.css';

import store from '../../../../store/store';

import BackPage from '../../../../assets/images/common/back-page.png';
import ArrowUpHIW from '../../../../assets/images/common/arrow-up-btn.png';
import HelloHowItWork from '../../../../assets/images/common/hello.png';
import SmileyMoneyHIW from '../../../../assets/images/common/smiley-money.png';
import SimleyGlasses from '../../../../assets/images/common/emoji-glasses.png';
import TradeHIW from '../../../../assets/images/common/handshake.png';
import SimleyEyeStars from '../../../../assets/images/common/emoji-stars-eyes.png';
import SimleyHurt from '../../../../assets/images/common/emoji-hurts.png';
import ArrowScrollHIW from '../../../../assets/images/common/arrow-down-yellow.png';
import CelebrateSmiley from '../../../../assets/images/common/smiley-celebrates.png';

import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { AnimateKeyframes } from "react-simple-animate";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function HowItWork() {
    store.dispatch({
        type: 'ui/navBarsDM/toggle',
        payload: { top: true, bottom: false },
      });
      store.dispatch({
        type: 'ui/navBarsSelectedSection/update',
        payload: "Notification",
      });
    const [ActionForm, setActionForm] = useState("TradePC");
    const windowInnerSize = useSelector(selectWindowInnerSize);

    const navigate = useNavigate();

    return (
        <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div id="HowDoesIWorkDiv" className="Page headline"
        style={{height: windowInnerSize.height}}>
            <img src={BackPage} alt="" id="BackPageNot" onClick={() => { navigate(-1) }} />

            <p id="OneBtnHIW" className="unnamed-character-style-1" onClick={() => { document.getElementById("HowDoesIWorkDiv").scrollTo({ top: window.innerWidth * 160 / 100, behavior: 'smooth' }); }}>1</p>
            <p id="TwoBtnHIW" className="unnamed-character-style-1" onClick={() => { document.getElementById("HowDoesIWorkDiv").scrollTo({ top: window.innerWidth * 318 / 100, behavior: 'smooth' }); }}>2</p>
            <p id="ThreeBtnHIW" className="unnamed-character-style-1" onClick={() => { document.getElementById("HowDoesIWorkDiv").scrollTo({ top: window.innerWidth * 706 / 100, behavior: 'smooth' }); }}>3</p>
            <img src={ArrowUpHIW} alt="" id="ArrowUpBtnHIW" onClick={() => { document.getElementById("HowDoesIWorkDiv").scrollTo({ top:'0vw', behavior: 'smooth' }); }} />

            <img src={HelloHowItWork} alt="" id="HelloHowItWork" />
            <p id="WelcomeTxtHIW" className="unnamed-character-style-1">WELCOME TO <br /> PASSION STOCKS</p>

            <p id="LetsTxtHIW"><span>Let’s give you the run down! </span><br /><br />

                PassionStocks is a platform that allows users to<br /> capitalize on their support of<br />
                social media influencers by purchasing their <br />limited passionstocks which can be traded on <br />our exchange or converted into passionproducts.<br /><br />

                Simultaneously empowering social media<br /> influencers to capitalize on their following by<br /> being able to sell and manage their social <br />products such as a story mention.
            </p>
            <img src={ArrowScrollHIW} alt="" id="ArrowScrollHIW1" />
            <p id="ScrollDownTxtHIW"> Scroll down to find out more!</p>

            <p id="OneHIW" className="unnamed-character-style-1">1</p>
            <p id="GetYouTxtHIW" className="unnamed-character-style-1">GET YOU SOME<br /> PASSIONCOIN</p>

            <img src={SmileyMoneyHIW} alt="" id="SmilyMoneyHIW" />
            <p id="DepositTxtHIW">Deposit your money in your<br /> wallet, and it will automatically<br /> convert into passioncoin. </p>

            <img src={ArrowScrollHIW} alt="" id="ArrowScrollHIW2" />

            <p id="TwoHIW" className="unnamed-character-style-1">2</p>
            <p id="PurchaseTxtHIW" className="unnamed-character-style-1">PURCHASE<br /> PASSIONSTOCKS</p>

            <img src={SimleyGlasses} alt="" id="SimleyGlasses" />
            <p id="HeadTxtHIW">Head to the exchange and<br /> purchase your favourite social <br />media influencer’s passionstocks. </p>

            <p id="WhatExpectHLHIW" className="unnamed-character-style-1">What to expect <br />
                On the exchange</p>

            <p id="ExpectExpHIW"><i>Market Value:</i><br />
                The price of passionstocks <span>appreciate</span> and <br /> <k>depreciate</k> according to the market behaviour<br /> of your fellow traders and the social media <br /> influencers popularity growth.<br /><br />

                <i>IPO passionstocks:</i><br />
                Social media influencers have only a limited<br /> amount of passionstocks authorized to trade.<br /> Get in early and buy them when they’re hot;<br /> straight from the social media influencer.<br /><br />

                <i>Post-IPO passionstocks:</i><br />
                These are passionstocks that have been<br /> traded before, but you’ll never know when<br /> they’ll be the next big thing.</p>

            <img src={CelebrateSmiley} alt="" id="CelebrateSmileyHIW" />
            <p id="CongHdlnHIW" className="unnamed-character-style-1">CONGRATULATIONS!</p>
            <p id="YouNowTxtHIW">You now own a passionstock in the your <br />favourite social star. Passioncoin will be <br />deducted from your wallet and replaced <br />with your new passionstock.            </p>

            <img src={ArrowScrollHIW} alt="" id="ArrowScrollHIW3" />

            <p id="ThreeHIW" className="unnamed-character-style-1">3</p>
            <p id="TradeForTxtHIW" className="unnamed-character-style-1">TRADE YOUR<br /> STOCK FOR...</p>
            <img src={TradeHIW} alt="" id="TradeHIW" />

            <p id="WhatDoWithPSHIW">What do I do with my <br />
                passionstock now?
            </p>

            <p id="TradeItPassionCoinBtnHIW" className="unnamed-character-style-1" onClick={setAsTradePC}>TRADE IT FOR <br /> PASSIONCOIN</p>
            <svg height="1.5vw" width="40%" id={ActionForm === "TradePC" ? "PCSubLine" : "Hide"}>
                <line x1="0" x2="100%" y1="0" y2="0" stroke="white" stroke-width="100%" stroke-linecap="butt" />
            </svg>
            <p id="TradeItPassionProduct" className="unnamed-character-style-1" onClick={setAsTradePP}>TRADE IT IN FOR A <br />PASSIONPRODUCT</p>
            <svg height="1.5vw" width="40%" id={ActionForm === "TradePP" ? "PPSubLine" : "Hide"}>
                <line x1="0" x2="100%" y1="0" y2="0" stroke="white" stroke-width="100%" stroke-linecap="butt" />
            </svg>

            <div id={ActionForm === "TradePC" ? "TradePassionCoinForm" : "Hide"}>
                <p id="TradeGetHdlnPCHIW" className="unnamed-character-style-1"><span>TRADE TO GET</span><br />PASSIONCOIN</p>
                <img src={SimleyEyeStars} alt="" id="SimleyEyeStarsHIW" />
                <p id="MakeTxtPCHIW">Make an offer on the exchange to sell your<br /> passionstock. If a fellow trader buys it you’ll <br />get passioncoin and can cash out. </p>
            </div>

            <div id={ActionForm === "TradePP" ? "TradePassionProductForm" : "Hide"}>
                <p id="TradeGetHdlnPPHIW" className="unnamed-character-style-1"><span>TRADE TO GET</span><br />PASSIONPRODUCT</p>
                <img src={SimleyHurt} alt="" id="SimleyHurtHIW" />
                <p id="InYourWalletTxtPCHIW"> In your wallet, Influencers in which you have<br /> brought passionstock in will offer you a<br /> passionproduct in exchange for a <br />passionstock. </p>
            </div>
        </div>
        </AnimateKeyframes>
    );

    function setAsTradePC() {
        if (ActionForm !== "TradePC") { setActionForm("TradePC") }
    }
    function setAsTradePP() {
        if (ActionForm !== "TradePP") { setActionForm("TradePP") }
    }
}