
export default function saleId(state = null, action) {
    switch (action.type) {
        case 'app/saleId/add': {
            return action.payload
        }
        case 'app/saleId/delete': {
            return null;
        }
        
        default:
            return state
    }
}