
export default function processIndex(state = 0, action) {
    switch (action.type) {
        case 'app/utiles/processIndex/add': {
            return action.payload
        }
        case 'app/utiles/processIndex/delete': {
            return 0
        }

        default:
            return state
    }
}